export const apiPath = "https://www.isupportcause.com/api";
export const productImages= "https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/products/";
export const campaignBanner_baseurl="https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/campaign/banner/";
export const campaignLogo_baseurl="https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/campaign/logo/";
export const Petition = "https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/petition/";
export const overlays = "https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/overlay/";
export const camapignImage="https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/campaign/";
export const productImage_BaseUrl="https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/products/";
export const viewActivities_img="https://s3.ap-south-1.amazonaws.com/isupportcause/uploads/merged/";
export const couponUrl="https://www.isupportcause.com/pricing?coupon=";
export const isupportcauseCampaign="https://www.isupportcause.com/";