import React from 'react'

export default()=>{
    return(
        <style>
            {`
              .viewAnnouncement-Table{
                width: 97%;
                margin: auto;
              }
              
              .viewAnnouncement-Table td, th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
           
              }
              .viewAnnouncement-Table-header{
                background:#01c2c4;
              }
              .main-div-of-file li{
                list-style:none;
              }
            `}
        </style>
    );
}