import React from 'react'

export default()=>{
    return(
        <style>
        {`
            .applying-flex{
                display:flex;
            }
       `}
        </style>
    );
}