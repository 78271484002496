import React from 'react'
import Style from './style'

export default ()=>{
    return(
            <>
            <div className="container-fluid loader-isc">
        <div className="loader "></div>
        </div>
         <Style/>
         </>
    );
}