import React from 'react'

export default()=>{
    return(
        <style>{`
    .setting-table-main {
      
      width: 97%;
      margin: auto;
    }
    
    .setting-table-main th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
      
    }
    .setting-table-main td {
      text-align: left;
      padding: 8px;
      
    }
    .setting-header-title{
        background:#4379E9;
        
    }
    .setting-edit-btn{
     height:35px;
    }
    .totalData-row{
      background:white;
    }
    
    `}
    </style>

    );
}