import React from 'react'


export default()=>{
    return(

        <style>
            {`
                .select-option-of-affilated-user {
                    margin-left: 13px;
                }
                .input-for-search-user-1 {
                    width: 177px;
                }
                .input-for-search-user {
                    width: 177px;
                    margin-left: 13px;
                }
                .appling-flex-btns{
                    display:flex;
                }
            `}
        </style>
    );
}