import React from 'react'


export default()=>{
    return(

        <style>
            {`
                .Table-of-administrator .input-for-search-user-1 {
                    width: 177px;
                }
                .Table-of-administrator .input-styling-0f-compaigns input{
                    border-radius: 3px;
                    width: 172px;
                    height: 32px;
                    border: 1px solid;
                }
                .btns-of-viewcompaign{
                    width: 290px;
                    height: 97px;
                }
                .customization-of-image-btn{
                    height: 38px;
                }
                
            `}
        </style>
    );
}