import React from 'react'

export default()=>{
    return(
        <style>
            {`
              .viewAnnouncement-Table{
                width: 97%;
                margin: auto;
              }
              
              .viewAnnouncement-Table  th {
                text-align: left;
                padding: 8px;
                border: 1px solid #dddddd;

              }
              .viewAnnouncement-Table td {
                text-align: left;
                padding: 8px;
               
              }
              .viewAnnouncement-Table-header{
                background:#4379E9;
              }
              .appling-flex-btns{
                  display:flex;
              }
            `}
        </style>
    );
}