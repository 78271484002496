import React from 'react'
export default ()=>{
    return(
        <style>
            {`
            .loader-isc{
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                min-height:750px;
            }
            .loader {
                border: 16px solid #f3f3f3;
                border-radius: 50%;
                border-top: 16px solid #3498db;
                width: 120px;
                height: 120px;
                -webkit-animation: spin 2s linear infinite; 
                animation: spin 2s linear infinite;
              }
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
        </style>
    )
}