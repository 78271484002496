import React from 'react'

export default () => {
    return (
        <style>
            {`
input .radio {
    display: none!important;
    
    `}
    </style>
);
}