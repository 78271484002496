import React from 'react'

export default () => {
    return (
        <style>
            {`
            .inputs-for-responsive{
                width: 900px;
                height: 40px;
                padding: 10px;
                border-radius: 4px;
                font-size: 15px;
                display: flex;
                border: 1px solid #989ca8;
            }
            `}
        </style>
    );
}