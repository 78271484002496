import React from 'react'
export default ()=>{
    return(
               <style>
                   {`
                     .dash-board-cards{
                         flex:1!important;
                      } 
                      .last-week-card-data-not-show-image{
                          width:100%;
                          justify-content:center;
                          align-items:center;
                          min-height:60vh;  
                      }
                      .padding-of-btn{
                        padding: 5px 20px 5px 20px;
                      }
                   `}
               </style>
        );
}