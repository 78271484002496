import React from 'react'

export default()=>{
    return(
    <style>{`
        .seaarch-list{
            border-left: 1px solid silver;
            border-right: 1px solid silver;
            border-bottom: 1px solid silver;
            width: 600px;
            padding: 8px 0px 8px 0px;
        }
        .seaarch-list:hover{
            background:#E9EDD7;
        }
    `}</style>
    );
}