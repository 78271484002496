import React from 'react'

export default()=>{
    return(
        <style>
            { `
                
            .view-subscription-btn-edit
            {
                height:40px;
            }

            .view-subscription-btn{
                height:40px;
                width: 169px;

            }
            
            
            
            `}
        </style>
    );
}