import React from 'react'


export default()=>{
    return(

        <style>
            {`
                .select-option-of-affilated-user {
                    margin-left: 13px;
                }
                .Table-of-administrator .input-for-search-user-1 {
                    width: 177px;
                }
                .Table-of-administrator .input-for-search-user {
                    width: 177px;
                    margin-left: 13px;
                }
                .Table-of-administrator .input-styling-0f-compaigns input{
                    border-radius: 3px;
                    width: 172px;
                    height: 32px;
                    border: 1px solid;
                }
                .Table-Header .search-filters{
                    width:100%;
                    justify-content:space-between;
                }
                .tr-border-bottom{
                    border-bottom:1px solid silver;
                    
                }
                .btns-of-viewcompaign{
                    width: 290px;
                    height: 97px;
                }
                .customization-of-image-btn{
                    height: 38px;
                }
                
            `}
        </style>
    );
}